* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

body,
html {
  margin: 0;
  min-height: 100%;
  font-family: "Poppins", sans-serif;
  width: 100%;
}

// Header fixed to top
.banner {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
}

// Ends Header fixed to top

body {
  font-size: 16px;
  font-family: $font-primary;
  font-weight: normal;
  // background: rgb(11 4 70);
  // background: radial-gradient(
  //   circle,
  //   #11038a 0%,
  //   #150238 51%,
  //   #000d42 100%
  // );
  background: linear-gradient(234deg,
      #361468 21.67%,
      #000d42 53.51%);
}

.form-check-input,
.form-select,
.nav-link:focus-visible {
  box-shadow: none !important;
}

.border-end-0 {
  border-right: 0;
}

.border-start-0 {
  border-left: 0;
}
.filter-item.filter-drop {
  background: #0b0446;
  color: #fff;
  padding: 7px 10px;
  position: absolute;
  z-index: 99;
  max-height: 350px;
  overflow-y: auto;
  max-width: 369px;
  ul {
    li {
      display: block;
      font-size: 14px;
      padding: 2px 3px;
      line-height: 23px;
    }
  }
}
.selected-country {
 display: flex;
    .dial-code {
      white-space: nowrap !important;
    }
}
.phone-input-container {
  max-width: 100% !important;
}

.modal-backdrop{
  z-index: 0 !important;
}